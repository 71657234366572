import { defineStore } from 'pinia'

type StoreState = {
  activeSelector: string
  activeStep: number
  steps: string[]
}

export const useProductTourStore = defineStore('product-tour', {
  state: (): StoreState => {
    return {
      activeSelector: '',
      activeStep: -1,
      steps: [],
    }
  },
  getters: {
    currentStep: (state) => state.steps[state.activeStep],
  },
  actions: {},
})
