import { useProductTourStore } from './product-tour.store'

export function setSteps(steps: string[]) {
  const store = useProductTourStore()
  store.$reset()
  store.steps = steps
}

export function nextStep() {
  const store = useProductTourStore()
  store.activeStep++
  const targetNode = document.querySelector(store.currentStep)
  if (!targetNode) return
  targetNode.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

export function end() {
  const store = useProductTourStore()
  store.$reset()
}
